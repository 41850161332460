<template>
  <div>
    <div class="qrcode" ref="qrCodeUrl" id="scanfdiv1">
    </div>
    <div style="float: left;margin-left: 30px">
      <p style="font-size: 10px;color: crimson">*未绑定不可使用此方法</p>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapMutations} from "vuex";
import QRCode from 'qrcodejs2'
import {ElMessage} from "element-plus";
var that;
export default {
  name: "scanf",
  data() {
    return {
      nuuid: '',
      openid: '',
      headimgurl: ''
    }
  },
  created() {
    that = this;
    // console.log(store.getters.GET_TOKEN)
  },
  computed: {
    ...mapGetters(['GET_TOKEN']),
    ...mapGetters(['GET_MENU'])
  },
  methods: {
    ...mapMutations(['SET_TOKEN']),
    ...mapMutations(['SET_MENU']),
    uuid() {
      var s = [];
      var hexDigits = '0123456789abcdef';
      for (var i = 0; i < 36; i++) {
        s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
      }
      s[14] = '4';
      s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1);
      s[8] = s[13] = s[18] = s[23] = '-';

      this.uuidA = s.join('');
      return this.uuidA;
    },
    creatQrCode() {
      var qrcode = new QRCode(this.$refs.qrCodeUrl, {
        text: this.nuuid, // 需要转换为二维码的内容
        width: 100,
        height: 100,
        colorDark: '#000000',
        colorLight: '#ffffff',
        correctLevel: QRCode.CorrectLevel.H
      })
    }
  },
  mounted() {
    this.nuuid = this.uuid();
    this.creatQrCode();
    var socket;
    if (typeof (WebSocket) == "undefined") {
      console.log("遗憾：您的浏览器不支持WebSocket");
    } else {
      console.log("恭喜：您的浏览器支持WebSocket");
      socket = new WebSocket("ws://localhost:8086/webSocket/" + this.nuuid);
      socket.onopen = function() {
        socket.send("消息发送测试(From Client)");
      };
      //收到消息事件
      socket.onmessage = function(msg) {
        console.log(msg)
        if (msg.data.includes('^') ){
          that.openid = msg.data.split('^')[0];
          that.headimgurl = msg.data.split('^')[1];
          that.axios.post("/userinfo/loginbyopenid", (response) => {
            if (response.message !==  null){
              that.SET_TOKEN(response.message);
              that.SET_MENU(response.obj);
              that.$router.push({
                path: '/manage'
              })
            }else {
              ElMessage.warning({
                message: '登录失败,请联系管理员！',
                type: 'warning',
              });
            }
          }, {
            openid: that.openid
          })
        }
      };
      socket.onclose = function() {
        //console.log("Socket已关闭");
      };
      //发生了错误事件
      socket.onerror = function() {
        //alert("Socket发生了错误");
      }

      //窗口关闭时，关闭连接
      window.unload=function() {
        socket.close();
      };
    }
  },
}
</script>

<style scoped>
#scanfdiv1{
  position: fixed;
  top: 50%;
  left: 50%;
  background-color: whitesmoke;
  padding: 10px;
  border-radius: 10px;
  -webkit-transform: translateX(-50%) translateY(-50%);
}
</style>
