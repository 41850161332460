<template>
  <div>
    <div>
      <p style="font-size: 18px; font-family: 苹方,serif;font-weight: bolder">账号密码登录</p>
    </div>
    <div style="margin-bottom: 10px">
      <el-input
          placeholder="请输入手机号"
          v-model="tel"
          size="small"
          clearable
          @blur="yztel">
      </el-input>
    </div>
    <div style="margin-bottom: 10px">
      <el-input
          placeholder="请输入密码"
          v-model="pas"
          size="small"
          clearable
          show-password>
      </el-input>
    </div>
    <div style="margin-top: 20px">
      <el-button type="primary" style="width: 100%;" @click="loginbypas">登录</el-button>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import { ElMessage, ElMessageBox } from 'element-plus';
import {Decrypt, Encrypt} from '@/utils/secret'

let that;
export default {
  name: "password",
  data() {
    return {
      tel: '',
      pas: '',
    }
  },
  computed: {
    ...mapGetters(['GET_TOKEN']),
    ...mapGetters(['GET_MENU'])
  },
  created() {
    that = this;
  },
  methods:{
    ...mapMutations(['SET_TOKEN']),
    ...mapMutations(['SET_MENU']),
    yztel(){
      const tel1 = Encrypt(this.tel);
      this.axios.post("/userinfo/yztel", (response) => {
        if (!response.obj){
          ElMessageBox.confirm('该手机号已注册，请直接登录')
              .then(() => {
              })
              .catch(() => {
                // catch error
              })
        }
      }, {
        phone: tel1
      })
    },
    loginbypas(res) {
        let appid = '2036043129'; // 腾讯云控制台中对应这个项目的 appid
        //生成一个滑块验证码对象
        let captcha = new TencentCaptcha(appid, function(res) {
          // 用户滑动结束或者关闭弹窗，腾讯返回的内容
          if (res.ret === 0) {
            that.axios.post("/userinfo/tenyz", (response) => {
              console.log(response)
              if (JSON.parse(response.obj).CaptchaCode === 1) {
                that.loginbypas1();
              }
              //若验证码到期，更改后端返回true,开放此注释代码，注释掉上面的if代码
              // if(response.obj){
              //   that.loginbypas1();
              // }
              //that.loginbypas1()
            },{
              ticket: res.ticket,
              randstr: res.randstr
            })
            //that.loginbypas1();
          } else {
            // 提示用户完成验证
          }
        });
        // 滑块显示
        captcha.show();
    },
    loginbypas1(){
      const tel1 = Encrypt(this.tel);
      const pass = Encrypt(this.pas);
      this.axios.post('/userinfo/loginbypas', (response) => {
        if (response.message !== null) {
	        document.cookie = 'Token_Tyhs'+ "=" + response.message +";expires="+this.expires+";path=/"+";domain=.cr12cz.cn"
          this.SET_TOKEN(response.message);
          this.SET_MENU(response.obj);
          this.$router.push({
            path: '/manage'
          })
        }
      },{
        tel : tel1,
        pas: pass
      })
    }
  }
}
</script>

<style scoped>

</style>
