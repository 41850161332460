<template>
  <div>
    <div id="logo">
      <img :src="logourl" style="max-width: 50%;max-height: 50%" alt=""/>
    </div>
    <div id="bc">
      <img :src="urlimg" style="width: 100%;height: 100%" alt=""/>
    </div>
     <div id="header_div">
       <div v-if="old">
         <div id="header_div1">
           <Loginform v-if="loginym"></Loginform>
           <Password v-if="passwordym"></Password>
         </div>
         <!--<div id="header_div3">-->
         <!--  <el-button type="text" @click="pasdlogin">账号密码登录</el-button>-->
         <!--  <el-button type="text" @click="phonelogin">手机号登录</el-button>-->
         <!--</div>-->
       </div>

       <div v-if="!old">
         <p style="color: red;font-weight: bolder;font-size: 25px">工序、初支请使用经济分析系统进行登录</p>
         <el-button type="danger" @click="openEms">经济分析系统登录入口</el-button>
         <el-button type="danger" @click="loginbefor">继续登录监管平台</el-button>
       </div>

<!--       <p><a href="http://ems.cr12cz.cn" style="text-decoration:none">经济分析系统登录入口</a></p>-->
     </div>
    <div style="width: 100%;position: fixed;text-align: center;bottom: 10px">
      <a href="https://beian.miit.gov.cn/" target="_blank" style="text-decoration:none;color: black;font-size: 16px">
        晋ICP备20002525号-5</a>
    </div>
  </div>
</template>


<script>
import Loginform from "@/views/login/loginform";
import Password from "@/views/login/password";
import Scanf from "@/views/login/scanf";
import {Encrypt} from "@/utils/secret";
import {ElMessage} from "element-plus";
export default {
  name: "login",
  components: {Loginform, Password},
  data(){
    return {
      passwordym: true,
      loginym: false,
      scanfym: false,
      urlimg: 'https://czsc.oss-cn-beijing.aliyuncs.com/qdimgs/backimg.png',
      logourl: 'https://czsc.oss-cn-beijing.aliyuncs.com/qdimgs/logo.png',
      // old: false
	    old: true
    }
  },
  created() {
    var url = location.search; //获取url中"?"符后的字串
    console.log(url)
    var theRequest = new Object();
    if (url.indexOf("?") != -1) {
      var str = url.substr(1);
      var strs = str.split("&");
      for (var i = 0; i < strs.length; i++) {
        theRequest[strs[i].split("=")[0]] = decodeURIComponent(strs[i].split("=")[1]);
      }
    }
    if (typeof(theRequest.tel) !== 'undefined'){
      localStorage.setItem("usertel", theRequest.tel)
      location.href = 'http://localhost:8081'
    }

    if (typeof(localStorage.getItem('usertel')) !== 'undefined'){
      console.log(localStorage.getItem('usertel'))
      const tel1 = Encrypt(localStorage.getItem('usertel'));
      this.axios.post("/userinfo/login", (response) => {
        if (response.message !== null) {
          this.SET_TOKEN(response.message);
          this.SET_MENU(response.obj);
          this.$router.push({
            path: '/manage'
          })
        } else {
          ElMessage.warning({
            message: '登录失败,请联系管理员！',
            type: 'warning',
          });
        }
      }, {
        phone: tel1
      })
    }
  },
  methods:{
    openEms(){
      window.open('http://ems.cr12cz.cn', "_self")
    },
    loginbefor(){
      this.old = true;
    },
    phonelogin(){
      this.loginym = true;
      this.passwordym = false;
    },
    pasdlogin(){
      this.loginym = false;
      this.passwordym = true;
    }
  }
}
</script>

<style scoped>
  #bc{
    width: 100%;
    height: 100%;
    top: 0; /*这里是设置与顶部的距离*/
    left: 0; /*这里是设置与左边的距离*/
    bottom: 0;
    right: 0;
    position:absolute;
    z-index: -1;
    overflow: hidden;
  }
  #logo{
    width: 80%;
    height: 80%;
    top: 20px; /*这里是设置与顶部的距离*/
    left: 50px; /*这里是设置与左边的距离*/
    bottom: 0;
    right: 0;
    position:absolute;
    z-index: 0;
    overflow: hidden;
  }
  #header_div{
    z-index: 0;
    position: fixed;
    top: 45%;
    left: 50%;
    //background-color: whitesmoke;
    padding: 10px;
    border-radius: 10px;
    -webkit-transform: translateX(-50%) translateY(-50%);
    text-align: center;
    background:rgba(255,255,255,0.5);
  }
  #header_div1{
    width: 350px;
    height: 200px;
  }
  #header_div3{
    margin-top: 30px;
    float:right
  }
</style>
