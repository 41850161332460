<template>
	<div>
		<div style="margin-bottom: 30px">
			<p style="font-size: 18px; font-family: 苹方,serif;font-weight: bolder">手机验证码登录</p>
		</div>
		<div style="margin-bottom: 30px">
			<el-input placeholder="请输入手机号" v-model="tel" size="small" clearable>
			</el-input>
		</div>
		<div>
			<el-row class="row-bg" :gutter="15">
				<el-col :span="16">
					<div class="grid-content bg-purple">
						<el-input placeholder="请输入验证码" v-model="yzm" size="small" clearable>
						</el-input>
					</div>
				</el-col>
				<el-col :span="6">
					<div class="grid-content bg-purple-light">
						<el-button :disabled="disabled" size="small" type="primary" plain @click="callback">{{btntxt}}
						</el-button>
					</div>

				</el-col>
			</el-row>
		</div>
		<div style="margin-top: 30px">
			<el-button type="primary" style="width: 100%;" @click="qdlogin">登录</el-button>
		</div>
	</div>
</template>

<script>
	import {
		ElMessage
	} from 'element-plus';
	import {
		Encrypt
	} from '@/utils/secret'
	import {
		mapGetters,
		mapMutations
	} from "vuex";
	var that;
	export default {
		name: "loginform",
		data() {
			return {
				tel: '',
				yzm: '',
				yzmbyurl: '',
				appId: '2036043129',
				extraBizParam: {
					bizState: '',
				},
				btntxt: '获取验证码',
				time: 60,
				disabled: false,
			}
		},
		computed: {
			...mapGetters(['GET_TOKEN']),
			...mapGetters(['GET_MENU'])
		},
		created() {
			that = this;
		},
		methods: {
			...mapMutations(['SET_TOKEN']),
			...mapMutations(['SET_MENU']),
      test(){
        this.axios.get('/other/exzx', (response) =>  {
          this.form.bdsts = response.obj
        })
      },
			callback(res) {
				if (!/^1(3[0-9]|4[01456879]|5[0-35-9]|6[2567]|7[0-8]|8[0-9]|9[0-35-9])\d{8}$/.test(this.tel)) {
					ElMessage.error('请填写正确的手机号')
				} else {
          that.time = 60;
          that.timer();

          let appid = '2036043129'; // 腾讯云控制台中对应这个项目的 appid
          //生成一个滑块验证码对象
          let captcha = new TencentCaptcha(appid, function(res) {
          	// 用户滑动结束或者关闭弹窗，腾讯返回的内容
          	if (res.ret === 0) {
          that.axios.post("/userinfo/tenyz", (response) => {
            if (JSON.parse(response.obj).CaptchaCode === 1){
              that.axios.post('https://www.autowired.xyz/sms/sendsms', (response) => {
                if (response.message === '验证码发送成功！') {
                  ElMessage.success({
                    message: '验证成功,请注意查收信息！',
                    type: 'success',
                  });
                  that.yzmbyurl = response.obj
                }
                if (response.message === '验证码还在有效期内！') {
                  ElMessage.warning({
                    message: '验证码还在有效期内！',
                    type: 'warning',
                  });
                  that.yzmbyurl = response.obj
                }
              }, {
                phone: that.tel
              })
            }else {
              ElMessage({
                message: '请刷新页面或联系管理员',
                type: 'warning',
              })
            }
          },{
            ticket: res.ticket,
            randstr: res.randstr
          })
          		} else {
          			// 提示用户完成验证
          		}
          	});
          	// 滑块显示
          	captcha.show();
          }
			},
			//发送手机验证码倒计时
			timer() {
				if (this.time > 0) {
					this.disabled = true;
					this.time--;
					this.btntxt = this.time + "秒";
					setTimeout(this.timer, 1000);
				} else {
					this.time = 0;
					this.btntxt = "发送验证码";
					this.disabled = false;
				}
			},
			qdlogin() {
				if (this.yzm === '') {
					ElMessage.error('请输入验证码！')
				}
				if (this.yzm !== this.yzmbyurl) {
					ElMessage.error('输入的验证码有误！')
				}
				if (this.yzm === this.yzmbyurl) {
					const tel1 = Encrypt(this.tel);
					this.axios.post("/userinfo/login", (response) => {
						if (response.message !== null) {
							this.SET_TOKEN(response.message);
							this.SET_MENU(response.obj);
							this.$router.push({
								path: '/manage'
							})
						} else {
							ElMessage.warning({
								message: '登录失败,请联系管理员！',
								type: 'warning',
							});
						}
					}, {
						phone: tel1
					})
				}
			}
		}
	}
</script>

<style scoped>

</style>
